
.loading-dots{
    font-size: 18px !important;
    color: #2185D0 !important;
}
.loading-dots::after {
    content: '\2022\2022\2022';
    animation: dots 1s infinite;
  }
  
  @keyframes dots {
    0% {
      content: '\2022';
    }
    33% {
      content: '\2022\2022';
    }
    66% {
      content: '\2022\2022\2022';
    }
  }
.flex-wrapperI{
  margin: 0px !important;
  padding: 0px !important;
  display:flex;
  overflow: hidden;
  width: 100%;
  background-color: white;
}

.flex-button{
  display: flex;
}
.div-app{
   width: '80%';
}
.div-config{
  width: '100%';
  display:flex;
  justify-content: flex-end;
  gap: 5px;
}
.dialog-content {
  padding: 16px 24px !important;
}
.headclass{
  color:white !important;
  padding-left: 30px !important;
  background-color: #1B7EBE !important;
}
.inputclass{
  padding-left: 25px !important;
}
.headclass-small{
  min-width: 20px !important;
  max-width :30px !important;
  align-items: center !important;
  padding: 0 !important;

}
.tableContainer{
  overflow-y: auto;
  height: calc(100vh - 20px);
  overflow-x: hidden;
}
.groupebutton{
  width: 100%;
  height: 40px;
  background: #fff;
  overflow-y: hidden;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: auto;
  margin: 0px !important;
}
.groupebutton1{
max-width: 100%;
display: flex;
overflow-x: auto;
}

.groupebutton::-webkit-scrollbar{
  height: 0;
  width: 0;
}
.button{
  color: gray;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width :fit-content !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 35px !important;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  border-radius: 50% 50% 0 0;
  border :none;
  

}
.active {
  display: flex;
  color: black;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: fit-content !important;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px;
  height: 35px !important;
  text-align: center;
  font-weight: 600;
  transition: opacity 0.5s ease;
  background-color: #fcfcfc;
  border-radius: 50% 50% 0 0;

}

.button1{
  min-width :150px !important;
  height: 15px !important;
  line-height: 0px;
  text-align: center;
  background-color:#ddd;
  margin-right: 2px;
  padding: 10px !important;
  }
.appTable{
  display: flex;
  flex-wrap: wrap;
  gap: 1;
}
.buttonDelete{
  color: red !important;
  padding: 10px;
 
}
.box {
  border: 1px solid #1B7EBE; 
  border-radius: 2px;
  margin: 5px; 
  display: flex;
  position: relative;
  flex-direction: row;
  white-space: nowrap;

}
.box >:first-child {
  margin-right: auto;
}
.appname{
  border: none;
  width: 100%;
  height: auto !important;
  justify-content: left !important;
  padding-left: 10px !important;
}
.appname:hover{
  background: white;
}
.edit-component{
  display: flex; 
  position: relative; 
  align-items: center;
  width: auto;
  border: none  !important;  
}
.edit-div{
  padding-left: 11px !important;
}

.div{
  width :  100% !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  
}
.transparent-div{
  width :  100% !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  background: rgba(0, 0, 0.5, 0.5);
  z-index: 9998;
  pointer-events: 'none';
}
.alert-class{
  position: fixed !important;
  transform: translate(-50%, -50%);
  top: 50% !important;
  left: 50% !important;
  z-index: 9999 !important;
}
.div-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.api {
  transition: background-color 0.3s;
  width : 50px !important
}
.deleteApiButton{
  opacity: 0;
}
.api:hover {
  background-color: red; 

}

.api:hover .deleteApiButton {
  display: flex; 
  opacity: 1;
}
.boxCircularProgress{
    display: flex;
    position: absolute;
    top:50%;
    left: 50%;
}

.icon {
  width: 38px;
  height: 38px;
  color: black;
  transition: transform 0.3s ease;
}
.logo {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}
.logo:hover {
  transform: scale(1.2); 
  cursor: pointer; 
  box-shadow: none !important;
  background-color: white !important;
}
.icon:hover {
  transform: scale(1.2); 
  cursor: pointer; 
  box-shadow: none !important;
  background-color: white !important;
}
.iconButtonRoot{
  height: fit-content;
  margin-top:12px !important;
  margin-left:2px !important;
  border-radius: 10% !important;
  width: fit-content !important;
}
.iconButtonRoot:hover {
  box-shadow: none !important;
  background-color: white !important;
}

.blackIcon{
  color : black
}
.redIcon{
  color: #1B7EBE;
}

.myIcons{
position: relative;
display: block;
line-height:25px;
font-size: 1.5em;
text-align: center;
transition: 0.5s;

}

.myText{
  position: absolute;
  color: gray;
  font-weight: 400;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
}

.active .myIcons{
   
   transform: translateY(50px);
}
.active .myText{
  opacity: 1;
  transform: translateY(-10px);
}

.scrollRight{
  position: absolute;
  /*background: linear-gradient(to right,white 50%,transparent);*/
}
.scrollleft{
   position: absolute;
   top: 50%;
   left: 10px; 
   transform: translateY(-50%);
   z-index: 1;
   display: none; 

   /*background: linear-gradient(to left,white 50%,transparent);*/

}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
 padding: 0% !important;
}
.firstPage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  margin-top: 300px;
}
.div-firstpage{
  font-size: 20px;
  font-weight: bold;
  width: 120px;
  border :1px solid #1B7EBE;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease;
}
.div-firstpage:hover{
  transform: scale(1.2); 
  cursor: pointer; 
}
.version-div{
  background-color: lightgray !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  bottom: 0 !important;
  /*padding-right: 8px !important;*/
  position: fixed;
  width: 100%;

}
.main-div{
  display :flex;
  align-items: center;
  gap :2px;
  margin:0px 4px
}
.version-iconbutton{
  background-color: white  !important;
  border-radius: 50% !important;
  width: 18px;
  height: 18px;
  margin: 5px !important;
}
.text-version{
  font-weight: bold !important;
  font-size: 12px !important;

}
.version-dialogTitle{
  padding:0 !important;
  justify-content: flex-start !important;
  background-color: rgb(243, 238, 238) !important;
  color: black !important;
  gap:35%

}
.version-cell{
  text-align: left !important;
  color: #fff !important;
  border:none;
  padding-left: 10px !important; 
  border: 1px solid white;
  width: 15% !important;
}
.version-container{
  border:none !important;
  border-radius: 0% !important;
  margin-top: 10px;

}

.version-puce p {
  list-style-type: disc;
  margin: 0% !important;
}
.version-puce{
  padding-left: 10px !important;
  border: 1px solid white;
}
.no-box-size{
  box-sizing: unset !important;
}
