@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
/* Include Montserrat font from Google Fonts */
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');*/

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: top;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: 'Montserrat', sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*, :after, :before{
  box-sizing: border-box;
}

.loading-dots{
    font-size: 18px !important;
    color: #2185D0 !important;
}
.loading-dots::after {
    content: '\2022\2022\2022';
    animation: dots 1s infinite;
  }
  
  @keyframes dots {
    0% {
      content: '\2022';
    }
    33% {
      content: '\2022\2022';
    }
    66% {
      content: '\2022\2022\2022';
    }
  }
.flex-wrapperI{
  margin: 0px !important;
  padding: 0px !important;
  display:flex;
  overflow: hidden;
  width: 100%;
  background-color: white;
}

.flex-button{
  display: flex;
}
.div-app{
   width: '80%';
}
.div-config{
  width: '100%';
  display:flex;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px;
}
.dialog-content {
  padding: 16px 24px !important;
}
.headclass{
  color:white !important;
  padding-left: 30px !important;
  background-color: #1B7EBE !important;
}
.inputclass{
  padding-left: 25px !important;
}
.headclass-small{
  min-width: 20px !important;
  max-width :30px !important;
  align-items: center !important;
  padding: 0 !important;

}
.tableContainer{
  overflow-y: auto;
  height: calc(100vh - 20px);
  overflow-x: hidden;
}
.groupebutton{
  width: 100%;
  height: 40px;
  background: #fff;
  overflow-y: hidden;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: auto;
  margin: 0px !important;
}
.groupebutton1{
max-width: 100%;
display: flex;
overflow-x: auto;
}

.groupebutton::-webkit-scrollbar{
  height: 0;
  width: 0;
}
.button{
  color: gray;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width :-webkit-fit-content !important;
  min-width :-moz-fit-content !important;
  min-width :fit-content !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 35px !important;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  border-radius: 50% 50% 0 0;
  border :none;
  

}
.active {
  display: flex;
  color: black;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px;
  height: 35px !important;
  text-align: center;
  font-weight: 600;
  transition: opacity 0.5s ease;
  background-color: #fcfcfc;
  border-radius: 50% 50% 0 0;

}

.button1{
  min-width :150px !important;
  height: 15px !important;
  line-height: 0px;
  text-align: center;
  background-color:#ddd;
  margin-right: 2px;
  padding: 10px !important;
  }
.appTable{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1;
  gap: 1;
}
.buttonDelete{
  color: red !important;
  padding: 10px;
 
}
.box {
  border: 1px solid #1B7EBE; 
  border-radius: 2px;
  margin: 5px; 
  display: flex;
  position: relative;
  flex-direction: row;
  white-space: nowrap;

}
.box >:first-child {
  margin-right: auto;
}
.appname{
  border: none;
  width: 100%;
  height: auto !important;
  justify-content: left !important;
  padding-left: 10px !important;
}
.appname:hover{
  background: white;
}
.edit-component{
  display: flex; 
  position: relative; 
  align-items: center;
  width: auto;
  border: none  !important;  
}
.edit-div{
  padding-left: 11px !important;
}

.div{
  width :  100% !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  
}
.transparent-div{
  width :  100% !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  background: rgba(0, 0, 0.5, 0.5);
  z-index: 9998;
  pointer-events: 'none';
}
.alert-class{
  position: fixed !important;
  transform: translate(-50%, -50%);
  top: 50% !important;
  left: 50% !important;
  z-index: 9999 !important;
}
.div-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.api {
  transition: background-color 0.3s;
  width : 50px !important
}
.deleteApiButton{
  opacity: 0;
}
.api:hover {
  background-color: red; 

}

.api:hover .deleteApiButton {
  display: flex; 
  opacity: 1;
}
.boxCircularProgress{
    display: flex;
    position: absolute;
    top:50%;
    left: 50%;
}

.icon {
  width: 38px;
  height: 38px;
  color: black;
  transition: transform 0.3s ease;
}
.logo {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}
.logo:hover {
  transform: scale(1.2); 
  cursor: pointer; 
  box-shadow: none !important;
  background-color: white !important;
}
.icon:hover {
  transform: scale(1.2); 
  cursor: pointer; 
  box-shadow: none !important;
  background-color: white !important;
}
.iconButtonRoot{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top:12px !important;
  margin-left:2px !important;
  border-radius: 10% !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.iconButtonRoot:hover {
  box-shadow: none !important;
  background-color: white !important;
}

.blackIcon{
  color : black
}
.redIcon{
  color: #1B7EBE;
}

.myIcons{
position: relative;
display: block;
line-height:25px;
font-size: 1.5em;
text-align: center;
transition: 0.5s;

}

.myText{
  position: absolute;
  color: gray;
  font-weight: 400;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
}

.active .myIcons{
   
   transform: translateY(50px);
}
.active .myText{
  opacity: 1;
  transform: translateY(-10px);
}

.scrollRight{
  position: absolute;
  /*background: linear-gradient(to right,white 50%,transparent);*/
}
.scrollleft{
   position: absolute;
   top: 50%;
   left: 10px; 
   transform: translateY(-50%);
   z-index: 1;
   display: none; 

   /*background: linear-gradient(to left,white 50%,transparent);*/

}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
 padding: 0% !important;
}
.firstPage{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 300px;
}
.div-firstpage{
  font-size: 20px;
  font-weight: bold;
  width: 120px;
  border :1px solid #1B7EBE;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease;
}
.div-firstpage:hover{
  transform: scale(1.2); 
  cursor: pointer; 
}
.version-div{
  background-color: lightgray !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  bottom: 0 !important;
  /*padding-right: 8px !important;*/
  position: fixed;
  width: 100%;

}
.main-div{
  display :flex;
  align-items: center;
  grid-gap :2px;
  gap :2px;
  margin:0px 4px
}
.version-iconbutton{
  background-color: white  !important;
  border-radius: 50% !important;
  width: 18px;
  height: 18px;
  margin: 5px !important;
}
.text-version{
  font-weight: bold !important;
  font-size: 12px !important;

}
.version-dialogTitle{
  padding:0 !important;
  justify-content: flex-start !important;
  background-color: rgb(243, 238, 238) !important;
  color: black !important;
  grid-gap:35%;
  gap:35%

}
.version-cell{
  text-align: left !important;
  color: #fff !important;
  border:none;
  padding-left: 10px !important; 
  border: 1px solid white;
  width: 15% !important;
}
.version-container{
  border:none !important;
  border-radius: 0% !important;
  margin-top: 10px;

}

.version-puce p {
  list-style-type: disc;
  margin: 0% !important;
}
.version-puce{
  padding-left: 10px !important;
  border: 1px solid white;
}
.no-box-size{
  box-sizing: unset !important;
}


.mainTab {
  display: flex;
  justify-content:center !important;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; 
  top: 0;
  position: fixed !important;
  width:100%
}
.helplogo{
  width: 20px !important;
  height: 20px !important;
 
 
}


.flexContainer {
  display: "flex";
  align-items: "center";
}

.gradRow1 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% auto;
  grid-gap: 10px;
  align-items: center;
}

.gradRow2 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 33% 33% auto;
  grid-gap: 10px;
  align-items: center;
}

.tab-bordure {
  border-left: 3px solid #1b7ebe; 
   border-right: 3px solid #1b7ebe;
   border-bottom: 3px solid #1b7ebe;

  border-collapse: collapse;
  width: 100%;
}

.head-color {
  background-color: #1b7ebe ;
  color: white !important;
  line-height: 0.75rem !important;
}
.tableRow  {
  transition: background-color 0.3s;
}

.deletebutton {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.api-cell:hover{
  background-color: rgb(220, 226, 255);

}

.api-cell:hover .deletebutton 
{
  opacity : 1;
}

.css-e39oxj-MuiTableCell-root{
  height: 80px !important;
}
.css-19vg8pn-MuiTableCell-root{
  height: 80px !important;
}
.css-1hsa187-MuiTableCell-root{
  height: 80px !important;

}
.css-17wgh7z-MuiTableRow-root {
 
  height: 40px !important;
 
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 3px;
}


.firstHead{
  width: 15px !important;
  }
.header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
 
}

.button {
  display: flex;
  flex-wrap: wrap;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  grid-gap: 5px;
  gap: 5px;
}

.edit {
  display: flex;
  flex-wrap: wrap;
}

/* .edit-grid {
  display: flex;
  flex-wrap: wrap;

  gap: 3px;
} */
 
  .edit-grid {
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
  }



.appsrec {
  border: 1px solid#1B7EBE;
  text-align: center;
  align-items: center;
  display: flex;
}

.appsrec> :first-child {
  margin-right: auto;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root  editable-input:hover {
  font-size: 14px;
border-radius : 0px ;
  border-color: #1B7EBE  !important ;
  outline: none !important;
  /* box-sizing: border-box !important; */
  width: auto !important;
  background-color: white;
  height: 32px !important;
  padding-left: 4px !important;
  /* padding:  4.5px 6px !important; */
  /* margin: 4px !important; */
}



/* .editable-input:focus {
  outline: 1px solid transparent;
 
} */

.editable-icon{
  color:#1B7EBE !important;
 
}
.editable-container{
  align-items: center;
  
}

.editbutton{
 height: 40px !important;
color : grey !important;
margin-right: auto !important;}

.detailTyp{
  color: rgb(212, 34, 34) !important;
}

/* .footer {
  display: grid;
  grid-template-columns:  repeat(3, 1fr); 
  gap: 100px;
  margin-top: 10px;
  background-color: rgb(122, 123, 129);
  color: white;
  border-radius: 10px;
} */


.footer{
  display: flex;
  position:fixed !important;
  bottom:0px !important;
  width:100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-Color: #E5E5E5;
  height: 35px;
  font-size: 14px;
  font-weight: bold !important;
  align-items: center;
}




.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px !important;
  padding-right: 50px !important;
  padding-left: 20px !important;
  position: fixed !important;
  top: 0;
  width: 100%;

}



.flex-wrapper1 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 25px;
  justify-content: flex-end;
  margin-top: 90px;
  flex-wrap: wrap;
  margin-left: auto;

}

@media (max-width:685px) {
  .flex-wrapper1 {

    justify-content: center;
    align-items: center;
    align-content: center;
  }

}


.addButton {

  margin-top: 19px;
}

.iconsearch {
  margin-top: 50px;
}





.row-1 {
  display: grid;
  grid-template-columns: 49.5% 49.5%;
  grid-gap: 10px;
  align-items: center;
  justify-content: center
}

.row-2 {
  display: grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-gap: 10px;
  align-items: center;
  justify-content: center
}

.table {

  overflow-x: hidden !important;
}



.table-header {
  background-color: #1B7EBE;
}

.table-row {
  transition: background-color 0.3s;

}

.api:hover,
.service-cell:hover,
.input-cell:hover,
.output-cell:hover {
  background-color: #e6f7ff;

}

.service-cell {
  padding-left: 13px !important;
}

.output-cell {
  padding-left: 15px !important;
}

.input-cell {
  padding-left: 17px !important;
}

.output-cell1 {
  width: 50%;

}

.input-cell1 {
  width: 50%;
  padding-left: 15px !important;
}

.api:hover .supbutton {
  opacity: 1;

}

.supbutton {
  opacity: 0;
  transition: opacity 0.5s ease;
}


.smaller-cell {
  width: 10%;
  font-weight: bold !important;
}

.smaller-name {
  width: 15%;

}

.smaller-direction {
  width: 20%;

}

.smaller-must {
  width: 20%;

}

.smaller-note {
  width: 35%;
}

.smaller-cell1 {
  width: 2.1%;
}

.tab {
  color: white !important;
}


.param-info {
  display: inline-block;
}

.param-type {
  color: #BF2B38;
  margin-right: 3px;
}

.param-name {
  font-weight: bold;
  margin-right: 2px;
}

.param-must {
  color: #BF2B38;
}

.Tab-App {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}

.Tab-App1 {
  overflow-x: hidden !important;
}

.Tab-App2 {
  overflow-x: hidden !important;
}


.grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  grid-gap: 8px !important;
  gap: 8px !important;
}


.grid-item {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  align-items: center;
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;

}

.grid-item:nth-child(even) {
  background-color: #E5E5E5;
  /* couleur pour les lignes paires */
}

/* #f0f0f0 */

.grid-item:nth-child(odd) {
  background-color: #ffffff;
  /* couleur pour les lignes impaires */
}

.Edit-Name {
  border: none !important;
  outline: none !important;
  box-sizing: border-box;
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding-left: 0px !important;
  padding: 4.5px 0px !important;
  font-size: 15px;
  margin: 0px !important;
}

.Edit-Name input {
  padding-left: 0 !important;
}

#boxCircularProgress {
  display: flex;
  position: absolute;
  top: 50%;
  left: 48%;
}


.versioning-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #versioning-section {
    grid-gap: 5px;
    gap: 5px;
  }
}



.table3 {
  width: 100%;

}



.version-cellf {
  background-color: #E5E5E5;
  border: 1px solid white !important;
  padding-left: 10px !important;
}

.date-cell {

  background-color: #E5E5E5;
  border: 1px solid white !important;
  padding-left: 10px !important;
}

.description-cell {
  background-color: #f0f0f0;
  border: 1px solid white !important;
  padding-right: 20px !important;

}

.smaller-cell3 {
  width: 15%;

}

.versioning-section {
  display: flex;
  position: fixed !important;
  bottom: 0px !important;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-Color: #E5E5E5;
  height: 25px;
  font-size: 14px;
  font-weight: bold !important;
}

.svgicon {

  width: 25px;
  height: 25px;
  justify-content: flex-start;
  position: fixed;
}

.logo2 {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
  margin-top: 16px;
}


.div-member-add {
  display: grid;
  margin-top: 30px;
  margin-bottom: 30px;
  grid-gap: 3px;
  gap: 3px;
  grid-template-columns: minmax(18%, auto) minmax(12%, auto) minmax(12%, auto) minmax(40%, auto) minmax(15%, auto) !important;
  width: 100% !important;
}

.add-member {
  width: 100% !important;
  display: grid !important;
  grid-row-gap: 10px !important;
  row-gap: 10px !important;
  margin-top: 40px !important;
  position: relative !important;
}


.form-add-member {
  width: '100%';
  display: grid;
  grid-row-gap: 60px;
  row-gap: 60px;
  margin-top: 13px;
  padding: 8px;
}

.Note-in-out {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
  width: 100% !important;
}

.Note-fields {
  grid-column: span 2 !important;

}

.twoIcons {
  display: flex;
  align-items: center;
}

.loading-dots {
  font-size: 18px !important;
  color: #2185D0 !important;
}

.loading-dots::after {
  content: '\2022\2022\2022';
  animation: dots 1s infinite;
}

@keyframes dots {
  0% {
    content: '\2022';
  }

  33% {
    content: '\2022\2022';
  }

  66% {
    content: '\2022\2022\2022';
  }
}
