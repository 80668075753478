.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px !important;
  padding-right: 50px !important;
  padding-left: 20px !important;
  position: fixed !important;
  top: 0;
  width: 100%;

}



.flex-wrapper1 {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  justify-content: flex-end;
  margin-top: 90px;
  flex-wrap: wrap;
  margin-left: auto;

}

@media (max-width:685px) {
  .flex-wrapper1 {

    justify-content: center;
    align-items: center;
    align-content: center;
  }

}


.addButton {

  margin-top: 19px;
}

.iconsearch {
  margin-top: 50px;
}





.row-1 {
  display: grid;
  grid-template-columns: 49.5% 49.5%;
  grid-gap: 10px;
  align-items: center;
  justify-content: center
}

.row-2 {
  display: grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-gap: 10px;
  align-items: center;
  justify-content: center
}

.table {

  overflow-x: hidden !important;
}



.table-header {
  background-color: #1B7EBE;
}

.table-row {
  transition: background-color 0.3s;

}

.api:hover,
.service-cell:hover,
.input-cell:hover,
.output-cell:hover {
  background-color: #e6f7ff;

}

.service-cell {
  padding-left: 13px !important;
}

.output-cell {
  padding-left: 15px !important;
}

.input-cell {
  padding-left: 17px !important;
}

.output-cell1 {
  width: 50%;

}

.input-cell1 {
  width: 50%;
  padding-left: 15px !important;
}

.api:hover .supbutton {
  opacity: 1;

}

.supbutton {
  opacity: 0;
  transition: opacity 0.5s ease;
}


.smaller-cell {
  width: 10%;
  font-weight: bold !important;
}

.smaller-name {
  width: 15%;

}

.smaller-direction {
  width: 20%;

}

.smaller-must {
  width: 20%;

}

.smaller-note {
  width: 35%;
}

.smaller-cell1 {
  width: 2.1%;
}

.tab {
  color: white !important;
}


.param-info {
  display: inline-block;
}

.param-type {
  color: #BF2B38;
  margin-right: 3px;
}

.param-name {
  font-weight: bold;
  margin-right: 2px;
}

.param-must {
  color: #BF2B38;
}

.Tab-App {
  display: flex;
  gap: 10px;
  height: 100%;
}

.Tab-App1 {
  overflow-x: hidden !important;
}

.Tab-App2 {
  overflow-x: hidden !important;
}


.grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  gap: 8px !important;
}


.grid-item {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  align-items: center;
  width: 100% !important;
  height: fit-content !important;

}

.grid-item:nth-child(even) {
  background-color: #E5E5E5;
  /* couleur pour les lignes paires */
}

/* #f0f0f0 */

.grid-item:nth-child(odd) {
  background-color: #ffffff;
  /* couleur pour les lignes impaires */
}

.Edit-Name {
  border: none !important;
  outline: none !important;
  box-sizing: border-box;
  width: 100% !important;
  height: fit-content !important;
  padding-left: 0px !important;
  padding: 4.5px 0px !important;
  font-size: 15px;
  margin: 0px !important;
}

.Edit-Name input {
  padding-left: 0 !important;
}

#boxCircularProgress {
  display: flex;
  position: absolute;
  top: 50%;
  left: 48%;
}


.versioning-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #versioning-section {
    gap: 5px;
  }
}



.table3 {
  width: 100%;

}



.version-cellf {
  background-color: #E5E5E5;
  border: 1px solid white !important;
  padding-left: 10px !important;
}

.date-cell {

  background-color: #E5E5E5;
  border: 1px solid white !important;
  padding-left: 10px !important;
}

.description-cell {
  background-color: #f0f0f0;
  border: 1px solid white !important;
  padding-right: 20px !important;

}

.smaller-cell3 {
  width: 15%;

}

.versioning-section {
  display: flex;
  position: fixed !important;
  bottom: 0px !important;
  gap: 10px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-Color: #E5E5E5;
  height: 25px;
  font-size: 14px;
  font-weight: bold !important;
}

.svgicon {

  width: 25px;
  height: 25px;
  justify-content: flex-start;
  position: fixed;
}

.logo2 {
  display: flex;
  gap: 2px;
  align-items: center;
  margin-top: 16px;
}


.div-member-add {
  display: grid;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 3px;
  grid-template-columns: minmax(18%, auto) minmax(12%, auto) minmax(12%, auto) minmax(40%, auto) minmax(15%, auto) !important;
  width: 100% !important;
}

.add-member {
  width: 100% !important;
  display: grid !important;
  row-gap: 10px !important;
  margin-top: 40px !important;
  position: relative !important;
}


.form-add-member {
  width: '100%';
  display: grid;
  row-gap: 60px;
  margin-top: 13px;
  padding: 8px;
}

.Note-in-out {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
  width: 100% !important;
}

.Note-fields {
  grid-column: span 2 !important;

}

.twoIcons {
  display: flex;
  align-items: center;
}

.loading-dots {
  font-size: 18px !important;
  color: #2185D0 !important;
}

.loading-dots::after {
  content: '\2022\2022\2022';
  animation: dots 1s infinite;
}

@keyframes dots {
  0% {
    content: '\2022';
  }

  33% {
    content: '\2022\2022';
  }

  66% {
    content: '\2022\2022\2022';
  }
}