
.mainTab {
  display: flex;
  justify-content:center !important;
  align-items: center;
  gap: 10px; 
  top: 0;
  position: fixed !important;
  width:100%
}
.helplogo{
  width: 20px !important;
  height: 20px !important;
 
 
}


.flexContainer {
  display: "flex";
  align-items: "center";
}

.gradRow1 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% auto;
  grid-gap: 10px;
  align-items: center;
}

.gradRow2 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 33% 33% auto;
  grid-gap: 10px;
  align-items: center;
}

.tab-bordure {
  border-left: 3px solid #1b7ebe; 
   border-right: 3px solid #1b7ebe;
   border-bottom: 3px solid #1b7ebe;

  border-collapse: collapse;
  width: 100%;
}

.head-color {
  background-color: #1b7ebe ;
  color: white !important;
  line-height: 0.75rem !important;
}
.tableRow  {
  transition: background-color 0.3s;
}

.deletebutton {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.api-cell:hover{
  background-color: rgb(220, 226, 255);

}

.api-cell:hover .deletebutton 
{
  opacity : 1;
}

.css-e39oxj-MuiTableCell-root{
  height: 80px !important;
}
.css-19vg8pn-MuiTableCell-root{
  height: 80px !important;
}
.css-1hsa187-MuiTableCell-root{
  height: 80px !important;

}
.css-17wgh7z-MuiTableRow-root {
 
  height: 40px !important;
 
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 3px;
}


.firstHead{
  width: 15px !important;
  }
.header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
 
}

.button {
  display: flex;
  flex-wrap: wrap;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 5px;
}

.edit {
  display: flex;
  flex-wrap: wrap;
}

/* .edit-grid {
  display: flex;
  flex-wrap: wrap;

  gap: 3px;
} */
 
  .edit-grid {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }



.appsrec {
  border: 1px solid#1B7EBE;
  text-align: center;
  align-items: center;
  display: flex;
}

.appsrec> :first-child {
  margin-right: auto;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root  editable-input:hover {
  font-size: 14px;
border-radius : 0px ;
  border-color: #1B7EBE  !important ;
  outline: none !important;
  /* box-sizing: border-box !important; */
  width: auto !important;
  background-color: white;
  height: 32px !important;
  padding-left: 4px !important;
  /* padding:  4.5px 6px !important; */
  /* margin: 4px !important; */
}



/* .editable-input:focus {
  outline: 1px solid transparent;
 
} */

.editable-icon{
  color:#1B7EBE !important;
 
}
.editable-container{
  align-items: center;
  
}

.editbutton{
 height: 40px !important;
color : grey !important;
margin-right: auto !important;}

.detailTyp{
  color: rgb(212, 34, 34) !important;
}

/* .footer {
  display: grid;
  grid-template-columns:  repeat(3, 1fr); 
  gap: 100px;
  margin-top: 10px;
  background-color: rgb(122, 123, 129);
  color: white;
  border-radius: 10px;
} */


.footer{
  display: flex;
  position:fixed !important;
  bottom:0px !important;
  width:100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-Color: #E5E5E5;
  height: 35px;
  font-size: 14px;
  font-weight: bold !important;
  align-items: center;
}



